@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  font-family: "Source Sance Pro", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff4400a9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff440088;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dropdown-menu {
  width: 18rem;
}

.dropdown-menu2 {
  width: 11.8rem;
}

.dropdown-menu3 {
  width: 10.2rem;
}

input.inputBox {
  font-size: 12px;
  padding: 12px 8px;
  cursor: pointer;
  border-radius: 3px;
  cursor: pointer;
  border: 0px;
  background-color: rgba(174, 174, 174, 0.248);
  outline: none;
}

.calendarWrap {
  display: inline-block;
  /* grid-column: 1; */
  position: relative;
  /* width: 400px; */
  /* margin-left: 5px; */
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 45px;
  border: 1px solid #ccc;
  z-index: 999;
}

.upwardCalendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 45px;
  border: 1px solid #ccc;
  z-index: 999;
}
